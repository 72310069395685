import { TechMapTmplOperationForm } from "@features/Production/TechMapTemplateFeatures/TechMapTmplOperationForm";
import {
    ERP_LOCATION_PATTERN,
    PDF_VIEWER_LOCATION_PATTERN,
    TMT_OPERATION_LOCATION_PATTERN,
} from "@shared/constants";
import { DrawerManager } from "@shared/ui/DrawerManager";
import { FileViewer } from "@shared/ui/FileViewer";
import { BizProcHistoryListWithLoad } from "@widgets/Portal/BizProcWidgets/BizProcHistory";
import { CreateEditEntityRowDrawer } from "@widgets/Portal/CreateEditEntityRowDrawer";

// interface IDrawerManagerWidget {}
// TODO REFACTOR
const DrawerManagerWidget = () => {
    return (
        <>
            <DrawerManager
                patterns={[
                    ERP_LOCATION_PATTERN,
                    TMT_OPERATION_LOCATION_PATTERN,
                    PDF_VIEWER_LOCATION_PATTERN,
                ]}
                drawer={(
                    drawerItem: TDrawerManagerItemPrepared,
                    closeDrawer: (item: TDrawerManagerItemPrepared) => void,
                    level?: number
                ) => (
                    <>
                        {drawerItem.pattern === ERP_LOCATION_PATTERN ? (
                            <CreateEditEntityRowDrawer
                                level={level}
                                entityTableName={drawerItem.name}
                                isOpenDrawer={drawerItem.isOpen}
                                entityRowId={drawerItem.id}
                                hideHistory={false}
                                history={(props) => (
                                    <BizProcHistoryListWithLoad {...props} />
                                )}
                                initialValues={drawerItem.additional?.initialValues}
                                openCloseDrawerWithNavigate={() =>
                                    closeDrawer(drawerItem)
                                }
                            />
                        ) : drawerItem.pattern === PDF_VIEWER_LOCATION_PATTERN ? (
                            <FileViewer
                                linkToFile={drawerItem.name}
                                closeDrawer={() => closeDrawer(drawerItem)}
                            />
                        ) : (
                            <TechMapTmplOperationForm
                                operationUuid={drawerItem.additional?.uuid}
                                parentUuid={drawerItem.additional?.parentUuid ?? null}
                                tmtUuid={drawerItem.additional?.tmtUuid ?? null}
                                operationId={drawerItem.id}
                                tmtId={drawerItem.additional?.tmtId}
                                level={level}
                                onCancel={() => closeDrawer(drawerItem)}
                            />
                        )}
                    </>
                )}
            />
        </>
    );
};

export default DrawerManagerWidget;
