import { EntityRowItem } from "@entities/Portal/EntityRow";
import TMTableOperations from "../TableItems/TMTableOperations";
import TMTableStatus from "../TableItems/TMTableStatus";
import TMTableApprove from "../TableItems/TMTableApprove";
import TMTableReject from "../TableItems/TMTableReject";
import { TechMapSpentTime } from "@features/Production/TechMapFeatures/TMOperations";
import TMTableApprovePartial from "../TableItems/TMTableApprovePartial";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";

export const getTableColumns = (canReviewSome: boolean, levelKey?: string | number) => {
    const columns = [
        {
            title: "№",
            dataIndex: "id",
            key: "id",
            render: (id: number, record: TTechMapStarted) => {
                return id;
            },
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: (_: any, record: TTechMapStarted) => {
                return record?.template?.name;
            },
        },

        {
            title: "Исполнитель",
            dataIndex: "user",
            key: "user",
            render: (user: TUser | undefined, record: TTechMapStarted) => {
                if (!user) return <>-</>;

                return <EntityRowItem table="users" name={user.name} id={user.id} />;
            },
        },

        {
            title: "Операция",
            dataIndex: "operation",
            key: "operation",
            render: (_: undefined, record: TTechMapStarted) => {
                return <TMTableOperations tm={record} />;
            },
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (status: TTechMapStatus, record: TTechMapStarted) => {
                const statusPrepared = record.is_paused ? "paused" : status;

                return <TMTableStatus status={statusPrepared} />;
            },
        },
        {
            title: "Время",
            dataIndex: "spent_time",
            key: "spent_time",
            render: (spentTime: number, record: TTechMapStarted) => {
                return (
                    <TechMapSpentTime
                        isPaused={record.status !== "in_progress" || record.is_paused}
                        spentTime={spentTime}
                    />
                );
            },
        },
    ];
    if (canReviewSome) {
        columns.push({
            title: "Согласовать",
            dataIndex: "approve",
            key: "approve",
            render: (_: undefined, record: TTechMapStarted) => {
                if (record.status !== "waiting") return <></>;
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <TMTableApprove techMap={record} levelKey={levelKey} />
                        <TMTableApprovePartial techMap={record} levelKey={levelKey} />
                    </div>
                );
            },
        });
        columns.push({
            title: "Отклонить",
            dataIndex: "reject",
            key: "reject",
            render: (_: undefined, record: TTechMapStarted) => {
                if (record.status !== "waiting") return <></>;
                return <TMTableReject techMap={record} levelKey={levelKey} />;
            },
        });
    }

    return columns;
};
